<template>
  <TransitionRoot as="template" :show="!modalSelectManufacturer.open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-gray-50  rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div :class="[modification.locked ? 'opacity-40 pointer-events-none select-none' : '', 'transition-all duration-300']">
              <div class="sm:flex sm:items-start">
                <div class="bg-green-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                  <ClipboardListIcon class="text-green-600 h-6 w-6" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left whitespace-nowrap">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-700">Przetwórz zamówienie</DialogTitle>
                  <p class="text-gray-500 font-semibold">{{order.id}}</p>
                </div>
                <div class="w-full flex justify-end">
                  <button type="button" :disabled="refreshingData" @click="refreshStorageData()" :class="[refreshingData ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']" class="justify-center w-36 transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5 whitespace-nowrap select-none">
                    <div v-if="refreshingData" class="spinner"></div>
                    <RefreshIcon v-if="!refreshingData" class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                    <span v-if="!refreshingData">Odśwież dane</span>
                  </button>
                </div>
              </div>
              <ul v-if="!isLoader" role="list" class="grid grid-cols-1 gap-6 mt-4">
                <li v-for="position, index in positions" :key="index" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                  <div class="flex w-full items-center justify-between space-x-6 p-6">
                    <div class="flex-1 truncate flex flex-col">
                      <div class="flex flex-col">
                        <div class="flex items-center space-x-3">
                          <p class="truncate font-medium text-gray-900">{{ position.product.name }} </p>
                        </div>
                        <p class="mt-1 truncate text-sm text-gray-500">Liczba dostępnych: {{ position.product.quantity }}</p>
                        <p v-if="$route.path == '/dashboard/storage/completion'" class="mt-1 truncate text-sm text-gray-500">Liczba buforowa: {{ position.product.bufferQuantity === -1 ? 0 : position.product.bufferQuantity }}</p>
                        <p v-if="typeof position.product.manufacturer === 'object'" class="mt-1 truncate text-sm text-gray-500">Producent {{ position.product.manufacturer.name }}</p>
                        <span v-if="$route.path == '/dashboard/storage/process' && !checkManufacturer(position.product)"  @click.prevent="openSelectManufacturerModal(position.product)" class="cursor-pointer text-blue-500 text-sm ">Dodaj producenta</span>
                      </div>
                      <div class="flex flex-row items-center mt-2 gap-1 text-gray-400 group cursor-pointer" @click.prevent="openAdditionalInformation(position.id, position)">
                        <span class="uppercase font-semibold text-xs select-none group-hover:text-gray-500 transition-all duration-500">Rozwiń dostępność paczek</span>
                        <ChevronDownIcon :class="[this.toggleInformation[position.id].open ? 'transform rotate-180' :'', 'w-5 h-5 group-hover:text-gray-500 transition-all duration-500']" />
                      </div>
                    </div>
                    <img class="h-10 w-10 flex-shrink-0 rounded-md bg-gray-300" :src="position.product.image.url || `https://api.dicebear.com/7.x/initials/svg?seed=${position.product.name}`" alt="" />
                  </div>
                  <div v-auto-animate>
                    <div v-if="this.toggleInformation[position.id].open" class="p-6">
                      <div v-if="position.parts != null">
                        <span class="uppercase text-sm font-semibold select-none text-gray-600">Dostępne paczki</span>
                        <div class="relative py-2">
                          <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                          </div>
                        </div>
                        <div v-for="(part, indx) in position.parts" :key="indx" class="text-sm flex justify-between">
                          <div>
                            <span class="font-semibold uppercase">Paczka nr {{indx+1}}:</span>
                            <span class="ml-2">{{part.name}}</span>
                          </div>
                          <div class="flex gap-1 text-sm text-gray-500 font-bold text-left w-16">
                            <span class="uppercase">ilość:</span>
                            <span :class="[part.quantity > 0 ? 'text-indigo-500' : 'text-red-500']">{{part.quantity}}</span>
                          </div>
                        </div>
                        <p class="mt-4 italic text-sm text-gray-500 semibold break-all whi">Proces zwiększenia dostępnej liczby sztuk o 1 wymaga zeskanowania <span class="text-indigo-500 font-semibold">({{position.amountOfPackages}})</span> paczek.</p>
                      </div>
                      <div v-else>
                        <span class="font-semibold text-gray-600 select-none">NIE ZESKANOWANO ŻADNEJ PACZKI</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="-mt-px flex divide-x divide-gray-200">

                      <div v-if="$route.path == '/dashboard/storage/completion' && position.source === 'order' && !position.subtracted && position.product.bufferQuantity === -1"  class="bg-gray-100 -ml-px flex w-0 flex-1 rounded-bl-lg select-none">
                        <div class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-red-500">
                          <ShoppingCartIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                          Nie zamówiono produktu
                        </div>
                      </div>

                      <div v-if="$route.path == '/dashboard/storage/completion' && position.source === 'warehouse'" class="bg-gray-100 transition-all duration-300 -ml-px flex w-0 flex-1 rounded-bl-lg select-none">
                        <div class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-green-500">
                          <CubeIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                          Dodano z magazynu
                        </div>
                      </div>

                      <div v-if="$route.path == '/dashboard/storage/completion' && position.source === 'order' && position.subtracted && !position.selected" class=" transition-all duration-300 -ml-px flex w-0 flex-1 rounded-bl-lg select-none" :class="['bg-gray-100']">
                        <div class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-green-500">
                          <TruckIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                          Dodano z zamówienia
                        </div>
                      </div>

                        <div v-if="$route.path == '/dashboard/storage/completion' && position.source === 'order' && !position.subtracted && position.product.bufferQuantity > -1" class="transition-all duration-300 flex w-0 flex-1 rounded-br-lg" :class="[position.selected ? 'bg-gray-100' : '', position.product.bufferQuantity === 0 && !position.selected ? '' : 'hover:bg-gray-200']">
                        <button :disabled="position.product.bufferQuantity === 0 && !position.selected" @click="confirmDelivery(position, true)" :class="[position.product.bufferQuantity === 0 && !position.selected ? 'opacity-20 cursor-not-allowed' :'']" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <TruckIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Potwierdź dostawę
                        </button>
                      </div>

                      <div v-if="$route.path !== '/dashboard/storage/completion'"  :class=" [position.selectedOption === 'warehouse' ? 'bg-gray-100' : '', position.product.quantity === 0 && position.selectedOption !== 'warehouse' ? 'opacity-20' : 'hover:bg-gray-200', 'transition-all duration-300 -ml-px flex w-0 flex-1 rounded-bl-lg']" >
                        <button @click="selectOption(position, 'warehouse')" :disabled="position.product.quantity === 0 && position.selectedOption !== 'warehouse'" class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900" :class=" [position.product.quantity === 0 && position.selectedOption !== 'warehouse' ? 'cursor-not-allowed' : '']">
                          <CubeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Dostępne
                        </button>
                      </div>
 
                      <div v-if="$route.path !== '/dashboard/storage/completion'" class="transition-all duration-300 hover:bg-gray-200 flex w-0 flex-1 rounded-br-lg" :class="[position.selectedOption === 'order' ? 'bg-gray-100' : '', typeof position.product.manufacturer === 'string' ? 'bg-gray-300 opacity-50 cursor-not-allowed' : '']">
                        <button :disabled="typeof position.product.manufacturer === 'string'" @click="selectOption(position, 'order')" :class="[typeof position.product.manufacturer === 'string' ? 'cursor-not-allowed bg-gray-300' : '']" class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <ShoppingCartIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          Zamów
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <LoadingSpinnerHub class="pb-6" v-else/>
              <div v-if="!isLoader" class="mt-5 sm:mt-4 mb-4 flex flex-row-reverse justify-between">
                <button v-if="$route.path === '/dashboard/storage/completion'" @click="processOrder()" :disabled="!checkIfSelectedOption()" type="button" :class="[checkIfSelectedOption() ? 'hover:bg-green-700 focus:ring-green-500 focus:ring-2 focus:ring-offset-2' : 'opacity-30 cursor-not-allowed', 'bg-green-600 transition-all duration-300 flex-1 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm']">Przetwórz</button>
                <button v-else @click="processOrder()" :disabled="!checkIfAllOptionsSelected()" type="button" :class="[checkIfAllOptionsSelected() ? 'hover:bg-green-700 focus:ring-green-500 focus:ring-2 focus:ring-offset-2' : 'opacity-30 cursor-not-allowed', 'transition-all duration-300 flex-1 bg-green-600 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm']">Przetwórz</button>
                <button @click="$emit('close')" type="button" class="transition-all duration-300 flex-1 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Anuluj</button>
              </div>
              <span v-if="modification.date != null" class="select-none text-xxs uppercase text-gray-500 font-semibold">Data ostatniej modyfikacji: <span class="lowercase text-indigo-500 font-bold">{{moment.unix(modification.date.seconds != null ? modification.date.seconds : modification.date).format('LL LTS')}}</span> </span>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <ModalSelectManufacturer v-if="modalSelectManufacturer.open" :product="modalSelectManufacturer.product" :allManufacturers="allManufacturers" :order="modalSelectManufacturer.order" @addManufacturer="addManufacturer" @close="modalSelectManufacturer.open = false"/>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ClipboardListIcon, ShoppingCartIcon, CubeIcon, CubeTransparentIcon, PlusIcon, TruckIcon, ChevronDownIcon, RefreshIcon } from '@heroicons/vue/outline'
import { db } from "@/firebase/gfbconf.js";
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import ModalSelectManufacturer from "../components/ModalSelectManufacturer.vue"
import moment from 'moment';
import axios from 'axios';

export default {
  props: ["order"],
  components: {
    ChevronDownIcon, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, LoadingSpinnerHub, ClipboardListIcon, ShoppingCartIcon, ShoppingCartIcon, CubeIcon, CubeTransparentIcon, PlusIcon, ModalSelectManufacturer, TruckIcon, RefreshIcon
  },
  data() {
    return {
      isLoader: false,
      positions: [],
      toggleInformation: {},
      modalSelectManufacturer: {
        open: false,
        variant: null,
        order: null
      },
      refreshingData: false,
      cart: {},
      moment: moment,
      allManufacturers: [],
      dbListener: null,
      dbListenerModificationDate: null,
      dbListenerPartsAvailability: null,
      modification: {
        date: null,
        locked: false
      },
      watchers: {
        modificationDate: null,
        availability: null
      }
    }
  },
  beforeUnmount() {
    if(this.dbListener !== null){
      this.dbListener();
    }
    if(this.dbListenerModificationDate !== null){
      this.dbListenerModificationDate();
    }
    if(this.dbListenerPartsAvailability !== null){
      this.dbListenerPartsAvailability();
    }
  },
  methods: {
    async refreshStorageData() {
      try {
        this.refreshingData = true
        const res = await axios.post(`${this.$store.state.apiLink}/product/synchronize/storage`, {
          positions: this.positions,
          order: this.order,
          meta: {
            createdDate: moment().toDate(),
            lastModificationDate: moment().toDate(),
            createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
            userId: this.$store.state.userData.id
          }
        })
        this.refreshingData = false
      } catch(error) {
        console.log(error)
      }
    },
    async getPartsFromProduct(position)
    {
      this.dbListenerModificationDate = db.collection('Products').doc(position.product.id)
      .onSnapshot(doc => {
        if(doc.exists) {
          let prod = doc.data().variants[position.product.variantId]
          position.amountOfPackages = prod.amountOfPackages
          position.parts = Object.values(prod.parts)
          position.product.quantity = prod.quantity
          this.watchers.availability = this.$watch('prod.parts', (newVal, oldVal) => {
            if(!_.isEqual(oldVal, newVal))
            {
              this.getPartsFromProduct();
            }
          });
        }
      })
    },
    openAdditionalInformation(indx, product)
    {
      if(this.toggleInformation[indx].open)
      {
        this.toggleInformation[indx].open = false
      }
      else
      {
        for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
          this.toggleInformation[i+1].open = false
        }
        this.getPartsFromProduct(product)
        this.toggleInformation[indx].open = true
      }
    },
    checkIfAllOptionsSelected() {
      let allPositionsSelected = true
      for(let position of this.positions) {
        if(!position.selected) {
          allPositionsSelected = false
          break
        }
      }
      return allPositionsSelected
    },
    checkIfSelectedOption() {
      let positionSelected = false
      for(let position of this.positions) {
        if(position.selected) {
          positionSelected = true
          break
        }
      }
      if(!positionSelected) {
        let allSubtracted = true
        for(let position of this.positions) {
          if(position.subtracted !== true) {
            allSubtracted = false
            break
          }
        }
        if(allSubtracted === true) positionSelected = true
      }
      return positionSelected
    },
    confirmDelivery(position) {
      if(!position.selected && position.product.bufferQuantity !== 0) {
        this.updateBufferQuantity("decrease", position)
        position.selected = true
      } else if(position.selected) {
        this.updateBufferQuantity("increase", position)
        position.selected = false
      }
    },
    async addManufacturer(body) {
      this.isLoader = true
        try {
          const res = await axios.post(`${this.$store.state.apiLink}/products/manufacturer/replace`, {...body, replaceOnOrders: true})
          if(res.data.success) {
              this.$store.commit('setNotification',{
                  show: true,
                  head: "Zaktualizowano producenta!",
                  subheader: ``,
                  success: true
              });
              this.createPositionsArr() 
          } else {
              this.$store.commit('setNotification',{
                  show: true,
                  head: "Nie udało się zaktualizować producenta!",
                  subheader: ``,
                  success: true
              }); 
          }
          this.isLoader = false
      } catch (error) {
          console.log(error);
          this.$store.commit('setNotification',{
              show: true,
              head: "Nie udało się zaktualizować producenta!",
              subheader: ``,
              success: true
          });
          this.isLoader = false
      }
    },
    async getAllManufacturers() {
      try {
        this.allManufacturers = []
        this.dbListener = await db.collection("Manufacturers").onSnapshot(querySnapshot => {
            this.allManufacturers = []
            querySnapshot.forEach(doc => {
                const manufacturer = {
                    name: doc.data().name,
                    ref: doc.ref,
                    id: doc.data().id
                }
            this.allManufacturers.push(manufacturer)
            })
        })
    } catch (error) {
          console.log(error);
      }
    },
    getModificationDate()
    {
      this.dbListenerModificationDate = db.collection("Orders").doc(this.order.id)
      .onSnapshot(doc => {
        if(doc.exists) {
          this.modification.date = doc.data().meta.lastModificationDate;
          this.watchers.modificationDate = this.$watch('order.meta.lastModificationDate', (newVal, oldVal) => {
            if(oldVal.seconds !== newVal.seconds)
            {
              this.createPositionsArr()
              this.modification.locked = true;
              setTimeout(() => {
                this.modification.locked = false;
              }, 4000);
            }
          });
        }
      })
    },
    async openSelectManufacturerModal(prod) {
      try {
          //get variant's product
          const product = await db.collection("Products").doc(prod.id).get()
          if(product.exists) {
              this.modalSelectManufacturer.product = product.data()
          } else {
              return console.log("Can't find product")
          }
          this.modalSelectManufacturer.open = true
      } catch (error) {
          console.log(error);
      }
    },
    checkManufacturer(product) 
    {
      //in order from orders this functionality is in 'checkExist' function
      if(typeof product.manufacturer === "string") {
          return false
      }
      return true
    },
    async processOrder() {
      try {
        this.isLoader = true
        if((this.$route.path === '/dashboard/storage/completion' && this.checkIfSelectedOption()) || (this.$route.path !== '/dashboard/storage/completion' && this.checkIfAllOptionsSelected())) {
            const res = await axios.post(`${this.$store.state.apiLink}/order/process`, {
            positions: this.positions,
            order: this.order,
            deliveryConfirm: this.$route.path == '/dashboard/storage/completion' ? true : false,
            meta: {
              createdDate: moment().toDate(),
              lastModificationDate: moment().toDate(),
              createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
              userId: this.$store.state.userData.id
            }
          })

          this.$emit('close')
          this.isLoader = false
          if(res.data.success) {
            let subheader = ""
            if(res.data.log === 'kompletowanie') {
              subheader = 'Zamówienie zmieniło status na "W trakcie kompletowania"'
            } else if(res.data.log === 'pakowanie') {
              subheader = 'Zamówienie zmieniło status na "Pakowanie"'
            }
            this.$store.commit('setNotification',{
                show: true,
                head: "Pomyślnie przetworzono zamówienie",
                subheader: subheader,
                success: true
            });
          } else {
            this.$store.commit('setNotification',{
              show: true,
              head: "Przetwarzanie zamówienia zakończone niepowodzeniem!",
              subheader: "Coś poszło nie tak..",
              success: false
            });
            throw "Error while processing order"
          }

          
        }
      } catch(error) {
        console.log(error)
        this.refreshStorageData()
      }
    },
    updateQuantity(option, position) {
      const dependentPositions = this.positions.filter(el => el.product.variantId === position.product.variantId)
      for(position of dependentPositions) {
        if(option === "increase") {
          position.product.quantity++
        } else {
          position.product.quantity--
        }
      }
    },
    updateBufferQuantity(option, position) {
      const dependentPositions = this.positions.filter(el => el.product.variantId === position.product.variantId)
      for(position of dependentPositions) {
        if(option === "increase") {
          position.product.bufferQuantity++
        } else {
          position.product.bufferQuantity--
        }
      }
    },
    async getVariantQuantity(variantId) {
      try {
        const productId = variantId.split("_p_")[1]
        const prodRef = await db.collection("Products").doc(productId).get()
        if(prodRef.exists) {
          let product = prodRef.data().variants[variantId]
          const quantity = product.quantity
          let bufferQuantity = 0
          if(product.buffer !== undefined && product.buffer[this.order.id] !== undefined) {
            bufferQuantity = product.buffer[this.order.id]
          } else {
            bufferQuantity = -1
          }
          return {quantity, bufferQuantity}
        } else {;
          return {quantity: 0, bufferQuantity: 0}
        }
      } catch (error) {
        console.log(error);
        return {quantity: 0, bufferQuantity: 0}
      }
    },
    async createPositionsArr() {
      this.isLoader = true
      this.positions = []
      const orderDoc = await db.collection("Orders").doc(this.order.id).get()
      if(!orderDoc.exists) {
        this.$store.commit('setNotification',{
            show: true,
            head: "Nie udało się pobrać pozycji!",
            subheader: ``,
            success: false
        }); 
        return
      }
      for(let position of Object.values(orderDoc.data().positions)) {
        // const manufacturer = position.product.manufacturer
        // positionCopy.product.manufacturer = manufacturer
        position.product.bufferQuantity = 0
        position.selected = false
        position.selectedOption = null
        const {quantity, bufferQuantity} = await this.getVariantQuantity(position.product.variantId)
        position.product.quantity = quantity
        position.product.bufferQuantity = bufferQuantity

        // const foundPosition = this.positions.find(el => el.product.variantId === positionCopy.product.variantId)
        // if(!foundPosition) {
        //   //if it is the first variant in this order then we have to get the quantity from db
        //   //otherwise we might get the quantity from the previous position with the same variant beacause it already got the quantity from db
        //   const {quantity, bufferQuantity} = await this.getVariantQuantity(positionCopy.product.variantId)

        //   positionCopy.product.quantity = quantity
        //   positionCopy.product.bufferQuantity = bufferQuantity
        // } else {
        //   positionCopy.product.quantity = foundPosition.product.quantity
        //   positionCopy.product.bufferQuantity = foundPosition.product.bufferQuantity
        //   positionCopy.productOrderId = foundPosition.productOrderId
        // }
        this.positions.push(position)
        this.toggleInformation[position.id] = {
          open: false
        }
      }
      this.isLoader = false
    },
    selectOption(position, option) {
      if(position.selectedOption === null) {
        if(option === "warehouse") {
          this.updateQuantity("decrease", position)
        }
        position.selected = true
        position.selectedOption = option
      } else if(position.selectedOption === option) {
        if(option === "warehouse") {
          this.updateQuantity("increase", position)
        }
        position.selected = false
        position.selectedOption = null
      } else {
        if(option === "warehouse") {
          this.updateQuantity("decrease", position)
        } else {
          this.updateQuantity("increase", position)
        }
        position.selected = true
        position.selectedOption = option
      }
    }
  },
  async created() {
    moment.locale('pl')
    await this.getAllManufacturers()
    await this.createPositionsArr()
    this.getModificationDate();
  },
}
</script>
<style scoped>
.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3.8px solid #616161;
  animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
        spinner-oaa3wk 2.2399999999999998s infinite linear;
}

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }
</style>